import React, { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader";
import ScrollToTop from "./hooks/ScrollToTop";

const Navbar = lazy(() => import("./layout/Navbar"));
const Footer = lazy(() => import("./layout/Footer"));
const Home = lazy(() => import("./pages/home/Home"));
const Predicta = lazy(() => import("./pages/predicta/Predicta"));
const RiskAnalysis = lazy(() => import("./pages/risk analysis/RiskAnalysis"));
const PredictionHealthFactors = lazy(() => import("./pages/prediction health factors/PredictionHealthFactors"));
const AutomatedCoaching = lazy(() => import("./pages/automated coaching/AutomatedCoaching"));
const Contact = lazy(() => import("./pages/contact/Contact"));
const Formations = lazy(() => import("./pages/formations/Formations"));
const AboutUs = lazy(() => import("./pages/about us/AboutUs"));
const DownloadFirstGuide = lazy(() => import("./pages/download guide/DownloadFirstGuide"));
const DownloadSecondGuide = lazy(() => import("./pages/download guide/DownloadSecondGuide"));
const FormationTms = lazy(() => import("./pages/formations/pages/FormationTms"));
const FormationGeste = lazy(() => import("./pages/formations/pages/FormationGeste"));
const FormationNeuroscience = lazy(() => import("./pages/formations/pages/FormationNeuroscience"));
const LivresBlocs = lazy(() => import("./pages/livres blocs/LivresBlocs"));
const ScientificLiterature = lazy(() => import("./pages/scientific literature/ScientificLiterature"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <ScrollToTop />
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/produit/logiciel-predicta" element={<Predicta />} />
              <Route path="/produit/analyse-des-risques" element={<RiskAnalysis />} />
              <Route path="/produit/prediction-des-facteurs-de-santé" element={<PredictionHealthFactors />} />
              <Route path="/produit/accompagnement-automatisé" element={<AutomatedCoaching />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/formations" element={<Formations />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/télécharger-premier-guide" element={<DownloadFirstGuide />} />
              <Route path="/télécharger-deuxième-guide" element={<DownloadSecondGuide />} />
              <Route path="/formations/tms" element={<FormationTms />} />
              <Route path="/formations/geste" element={<FormationGeste />} />
              <Route path="/formations/neuroscience" element={<FormationNeuroscience />} />
              <Route path="/ressources/livres-blancs" element={<LivresBlocs />} />
              <Route path="/ressources/littérature-scientifique" element={<ScientificLiterature />} />
            </Routes>
            <Footer />
          </Suspense>
        </BrowserRouter>
      </>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;